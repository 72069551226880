import { inject, Injectable } from '@angular/core';
import { CanDeactivate } from '@angular/router';
import { BaseView } from '../classes/baseview';
import { PageService } from '../service/page.service';

@Injectable({
    providedIn: 'root',
})
export class CanDeactivateGuard implements CanDeactivate<BaseView> {

    private pageservice: PageService = inject(PageService);

    canDeactivate(component: BaseView) {
        return new Promise<boolean>((resolve) => {
            if (component?.isStateChanged() && !component.isCompBusy()) {
                component.handleStateChangedNavigation().then(x => {
                    resolve(x);
                }).catch(() => { resolve(false) })
            }
            else {
                resolve(true);
            }
        });
    }
}
